<template>
  <v-container fluid>
    <v-card elevation="0" class="mt-0 ml-4">
      <v-tabs
        v-model="tab"
        @change="tabChanged(tab)"
        background-color="#5E2FBE"
        dark
        disabled
      >
        <v-tab :disabled="loading" v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
        <v-btn
          outlined
          small
          class="ml-auto my-auto mr-2 rounded-0"
          v-if="$admin.can('transfer-export')"
          @click="getCSV(filters)"
          :loading="is_loading_csv"
          :disabled="is_loading_csv"
        >
          <v-icon color="white" class="mr-2">
            mdi-arrow-down-bold-circle-outline
          </v-icon>
          {{ $t("export_detail_csv") }}
        </v-btn>
        <v-btn
          outlined
          small
          class="my-auto my-auto mr-2 rounded-0"
          v-if="$admin.can('transfer-export')"
          @click="getCSVResult(filters)"
          :loading="is_loading_csv_result"
          :disabled="is_loading_csv_result"
        >
          <v-icon color="white" class="mr-2">
            mdi-arrow-down-bold-circle-outline
          </v-icon>
          {{ $t("export_result_csv") }}
        </v-btn>
        <v-btn
          outlined
          small
          class="my-auto mr-2 rounded-0"
          v-if="$admin.can('transfer-import')"
          @click="toggleImportModal()"
        >
          <v-icon color="white" class="mr-2">
            mdi-arrow-up-bold-circle-outline
          </v-icon>
          {{ $t("import_csv") }}
        </v-btn>

        <div class="white my-auto mr-2">
          <v-btn
            outlined
            small
            class="primary--text my-auto rounded-0"
            v-if="$admin.can('transfer-create')"
            @click="addNewTransfer()"
          >
            {{ $t("add_new_transfer") }}
          </v-btn>
        </div>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <draft-tab
            :filters="filters"
            v-if="item.content == 'draft'"
            :isActive="items[tab]?.content == 'draft'"
          ></draft-tab>

          <outgoing-tab
            :filters="filters"
            v-if="item.content == 'outgoing'"
            :isActive="items[tab]?.content == 'outgoing'"
          ></outgoing-tab>
          <incoming-tab
            :filters="filters"
            v-if="item.content == 'incoming'"
            :isActive="items[tab]?.content == 'incoming'"
          ></incoming-tab>
          <history-tab
            v-if="item.content == 'history'"
            :filters="filters"
            :isActive="items[tab]?.content == 'history'"
          >
          </history-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <import-csv
      :isModalOpen="isImportModalOpen"
      :toggleModal="() => toggleImportModal()"
    ></import-csv>
  </v-container>
</template>

<script>
export default {
  components: {
    DraftTab: () => import("./tabs/DraftTab.vue"),
    OutgoingTab: () => import("./tabs/OutgoingTab.vue"),
    IncomingTab: () => import("./tabs/IncomingTab.vue"),
    HistoryTab: () => import("./tabs/HistoryTab.vue"),
    ImportCsv: () => import("./modals/ImportTransferCSVModal.vue"),
  },

  computed: {
    filters: {
      get() {
        return this.$store.state.transferProducts.filters;
      },
      set(value) {
        this.$store.commit("transferProducts/SET_FILTERS", value);
      },
    },

    currentTab: {
      get() {
        return this.$store.state.transferProducts.currentTab;
      },
      set(value) {
        this.$store.commit("transferProducts/CURRENT_TAB", value);
      },
    },
  },
  methods: {
    toggleImportModal() {
      this.isImportModalOpen = !this.isImportModalOpen;
    },
    async tabChanged(index) {
      try {
        this.currentTab = index;
        this.$store.commit("transferProducts/CLEAN_LIST");
        this.$store.dispatch("transferProducts/setFilters", {
          transfer_status_name: this.items[index].status,
          flow: this.items[index].flow,
        });

        this.$store.commit("SET_PAGE_SUBTITLE", "");
        this.$store.commit("SET_SUBTITLE_ID", "");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    addNewTransfer() {
      this.$router.push({ name: "transfers.create" });
    },

    async getCSV(filters) {
      this.is_loading_csv = true;

      await this.$store
        .dispatch("transferProducts/exportCSV", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "transfers.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch(
            "alerts/error",
            error.response?.data?.message ?? error.response?.statusText
          );
        });
    },
    async getCSVResult(filters) {
      this.is_loading_csv_result = true;

      await this.$store
        .dispatch("transferProducts/exportCSVResult", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "transfers.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv_result = false;
        })
        .catch((error) => {
          this.is_loading_csv_result = false;
          this.$store.dispatch(
            "alerts/error",
            error.response?.data?.message ?? error.response?.statusText
          );
        });
    },
  },

  async created() {
    this.tab = this.currentTab;
    this.filters.transfer_status_name =
      this?.items[this.tab]?.status ?? "draft";
  },

  data() {
    return {
      isImportModalOpen: false,
      is_loading_csv: false,
      is_loading_detail_csv: false,
      is_loading_csv_result: false,
      tab: null,
      loading: false,
      items: [
        {
          tab: this.$t("draft"),
          content: "draft",
          status: "draft",
          flow: "outgoing",
        },
        {
          tab: this.$t("outgoing"),
          content: "outgoing",
          status: "open",
          flow: "outgoing",
        },
        {
          tab: this.$t("incoming"),
          content: "incoming",
          status: "open",
          flow: "incoming",
        },

        {
          tab: this.$t("history"),
          content: "history",
          status: "history",
          flow: "outgoing",
        },
      ],
    };
  },
};
</script>
